.head {
    background-color: #5372c0;
    padding: 10px 0;
    color: #ffff;
   

}
.head i {
    margin-right: 10px;
}
.head label {
    margin-right: 30px;
    font-size: 16px;

}
/* heade */
.search {
    padding: 20px 0;
}
.search .logo img {
    width: auto;
    height: auto;
}
.search .with {
    width: 5%;
}
.search-box{
    width: 70%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    
}
.search-box i {
width: 5%;
padding: 15px 20px;
text-align: center;
opacity: 0.5;
font-size: 20px;
}
.search-box input{
    width: 100%;
    padding: 10px;
    opacity: 0.5;
    
}
::placeholder {
    font-size: 15px;
}
.search-box span{
    width: 20%;
    padding: 10px;
    font-size: 15px;
    opacity: 0.5;
    margin-top: 10px;
}
.cart {
    position: relative;
    display: flex;
    margin-top: 15px;
     
}
.cart> span {
    position: absolute;
    top: -10px;
    right: 0px;
    width: 20px;
     height: 20px;
  border-radius: 50%;
    background-color: #e94560;
    justify-content: center; 
    align-items: center;
       display: flex;
}
.catagories{
    background-color: #f6f9fc;
    padding: 0 30px;
    border-radius: 5px;
    height: 6vh;
    margin-top: 5px;
}
.catagories span{
    margin-right: 20px;
    font-size: 30px;
    border-radius: 5px;
    margin-top: 10px;
}
.catagories h4{
    font-weight: 500;
    margin-top: 12px;

}
.catagories h4 i {
    margin-left: 10px;
}
.header {
    height: 8vh;
    box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
}
.header ul {
    line-height: 8vh;

}
.header ul li {
    margin-left: 30px;
}
.header ul li a{
 transition: 0.5s;
 font-size: 17px;
 font-weight: 400;
}
.header ul li a:hover{
    color: #e94560;
}
.open {
    display: none;
}
.search.active{
    height: 12vh;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #ffff;
    box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}
@media (max-width:768px) {
.header ul {
    padding-top: 30px;

}
.nav-links-MobileMenu{
    position: absolute;
    display: block;
    list-style-type: none;
    left: 0;
    top: 0;
    transition: 0.5s;
   
    width: 80%;
    height: 100vh;
    background-color: #fff;
    z-index: 888;
    box-shadow: rgba(50,50, 93, 0.23) 0 50px 100px -20px;
}
.header ul li {
    width: 100%;
    transition: all 0.5 ease-in-out;
    text-transform: uppercase;
    line-height: 70px;
}


.header .link {
    display: none;
}  
.close, .open {
    position: absolute;
    top: 25%;
    right: 25px;
    color: #ff014f;
    display: block;
    font-size: 25px;
    z-index: 999;
    

}

.close{
    right: 45%;
    top: 5%;
}

}