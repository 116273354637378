@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,800&display=swap');
.App {
  background: color #f5f6f7;
  
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
  }
  .container {
    max-width: 90%;
    margin: auto;
  }
  body {
    
    font-family: 'Poppins', sans-serif;
  }
  a {
    text-decoration: none;
    color: #1f1f1f;
  }
  li {
    list-style: none;
  }
  .container {
    max-width: 90%;
    margin: auto;
  }
  .d_flex {
    display: flex;
    justify-content: space-between;
  }
  .c_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .f_flex {
    display: flex;
  }
  .capitalize{
    text-transform: capitalize;
  }
  .RText {
    text-align: right;
  }
  .uppercase {
    text-transform: uppercase;
  }
input ,button{
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}
.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
 background-color: #f3f5f9 ;
  text-align: center;
  padding-left: 20px;
  border-radius: 50%;
  display: flex;

}
.boxShadow{
  box-shadow: rgba(42, 52, 69, 0.1) 0 4px 16px;
}
.contentWith{
  width: 77%;
}
.category {
  width: 23%;
  box-shadow: rgba(42, 52, 69, 0.1) 0px 4px 16px;
  height: 610px;
  padding: 15px 0px;
  margin-top: 5px;
  border-radius: 5px;
}
.category img{
  width: 30px;
  height: 30px;
  margin-top: 5px;
}
.category span{
  margin: 10px 20px;
  text-transform: capitalize;
}
.category .box{
  transition: 0.5s;
  padding: 0 20px;

}
.category .box:hover{
  cursor: pointer;
  color: #e92b84;
}
.btn-primary {
  background: #e94560;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading i {
  color: #e94560;
  margin: 10px;
}
.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}
.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}
.product h3 {
  font-weight: 400;
  font-size: 17px;
}
.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}
.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}
.price button {
  background: none;
  color: #e94560;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}
.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}
.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}
.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}
.product:hover .product-like {
  opacity: 1;
}

/*---------product-----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}